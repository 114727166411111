/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import axiosInstance from "./axios-config";

interface FetchFromAPIOptions {
  baseUrl?: string;
  endpoint: string;
  method: "get" | "post" | "put" | "delete" | "patch";
  queryParameters?: Record<string, any>;
  body?: any;
  headers?: Record<string, string>;
  contentType?: string;
  responseType?: ResponseType;
  returnSpecificErrorMessage?: boolean;
}

export async function fetchFromAPI<T>({
  baseUrl = process.env.NEXT_PUBLIC_API_HOST || "",
  endpoint,
  method = "get",
  queryParameters,
  body,
  headers,
  contentType,
  responseType,
  returnSpecificErrorMessage,
}: FetchFromAPIOptions): Promise<T> {
  // Construct the full URL with optional query parameters
  const url = new URL(endpoint, baseUrl);

  const config: AxiosRequestConfig = {
    method,
    url: url.toString(),
    headers: {
      "Content-Type": contentType ?? "application/json",
      ...headers,
    },
    ...(method !== "get" && { data: body }),
    ...(method === "get" && { data: {}, params: queryParameters }),
  };
  if (responseType) {
    config.responseType = responseType;
  }

  try {
    const response: AxiosResponse<T, any> = await axiosInstance(config);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // If available return API generatad error message, otherwise generic fetch error message
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const message: string | undefined = error?.response?.data?.message;
      const statusCode: number | undefined = error.response?.status;

      if (returnSpecificErrorMessage) {
        if (statusCode && statusCode >= 500 && statusCode < 600) {
          throw new Error(`Server error: ${statusCode}`);
        } else if (statusCode && statusCode >= 400 && statusCode < 500) {
          throw new Error(`Client error: ${statusCode}`);
        } else {
          throw new Error(error.message);
        }
      } else {
        throw new Error(message ?? error.message);
      }
    } else {
      // Handle unexpected errors
      throw new Error("An unexpected error occurred");
    }
  }
}
