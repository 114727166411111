/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */

import { usePayAnInvoiceFormData } from "@/store/payAnInvoiceFormsStore";
import { useEffect, useState } from "react";
import AppText from "../other/AppText";
import AppButton from "../buttons/AppButton";

const FormRequestPayAnInvoiceStep3 = ({ formDataTab }: { formDataTab: any }) => {
  const { setStep, formData } = usePayAnInvoiceFormData();

  const [externalKey, setExternalKey] = useState("");
  const [gwUrl, setGwUrl] = useState("");

  const callAPi = async () => {
    const acc = {
      accountNumber: `${formData.PAI_Account}`,
      firstName: `${formData.PAI_First_Name}`,
      surName: `${formData.PAI_Last_Name}`,
      emailId: `${formData.PAI_Email}`,
      phoneNumber: `${formData.PAI_Phone.split(" ").slice(1).join("-")}`,
    };
    setExternalKey(formData.PAI_Account);
    setGwUrl(
      `${process.env.NEXT_PUBLIC_PGW_ORIGIN}external-payment?referenceNumber=${acc.accountNumber}&firstName=${acc.firstName}&surName=${acc.surName}&emailId=${acc.emailId}&phoneNumber=${acc.phoneNumber}`
    );
  };

  useEffect(() => {
    callAPi();
  }, []);
  return (
    <div className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-6 pt-12 bg-white">
      <AppText type="SUB_HEADING_MEDIUM" className="mb-[28px]">
        {formDataTab.groups[0].heading}
      </AppText>
      {externalKey ? <iframe src={gwUrl} title="Payment" className="w-full h-[400px] border rounded-md" /> : ""}

      <div className="mt-24 mb-[78px]">
        <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>
        <div className="flex flex-row flex-wrap justify-end gap-4 bg-white">
          <AppButton
            intent="secondary"
            label="Back"
            type="button"
            className="px-10"
            onMouseDown={() => {
              setStep(2);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormRequestPayAnInvoiceStep3;
