export const RECORD_MAPPING: { [key: string]: string } = {
  Service: "0128c000001zT4PAAU",
  Invoice: "0128c000001zT4PAAU",
  Relocation: "0128c000001zT4PAAU",
  "Lease Extension": "0128c000001zT4PAAU",
  Return: "0128c000001zT4PAAU",
  "AP Information": "0128c000001zT4PAAU",
};

export const ORIGINAL_SERVICE_NAME: { [key: string]: string } = {
  "Service a unit": "Service",
  "Invoice review": "Invoice",
  "Relocate a unit": "Relocation",
  "Lease extension": "Lease Extension",
  "Return a unit": "Return",
  "AP Information": "AP Information",
};

export const ZIP_CODE_API_URL = "https://api.zippopotam.us";

export const COUNTRY_SHORT_NAME: { [key: string]: string } = {
  "united states": "us",
  canada: "ca",
};

export const API_CONFIG = {
  BASE_URL: process.env.NEXT_PUBLIC_API_HOST,
  GUEST_KEY: process.env.NEXT_GUEST_PAYMENT_KEY,
  ENDPOINTS: {
    registration: "/api/registration",
    invoices: "/api/invoices",
  },
};

export const OTHERS_SELECT_OPTION_CODE = {
  othersReasonCode: "008",
};
