/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";

import { Select } from "../other/Select";
import Pagination from "../other/Pagination";
import { ITEMS_PER_PAGE } from "../other/constants";
import ContentLayout from "../layouts/ContentLayout";
import { SearchInput } from "../other/SearchInput";
import { mockBogsList } from "@/mocks/indexListMocks";
import AppText from "../other/AppText";
import CustomLink from "../other/CustomLink";
import Image from "next/image";
import { AppIconButton } from "../buttons/AppIconButton";
import { ChevronRight } from "lucide-react";

import { WS_PLACEHOLDER_BLUR_DATA } from "@/mocks/constants";

interface ICaseStudy {
  id?: string;
  dict: { [key: string]: string };
  preview: boolean;
  categories: any;
  list: any;
  totalSum: number;
  storybook?: boolean;
  paddingBottom?: string;
}

const BlogsList: React.FC<ICaseStudy> = ({
  dict,
  preview,
  categories,
  list,
  totalSum,
  id,
  storybook,
  paddingBottom,
}) => {
  const [cards, setCards] = useState<any>(list);
  const [allCategories] = useState<{ name: string; value: string }[] | null>(categories);
  const [selectedCategory, setSelectedCategory] = useState<any>(categories[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [total, setTotal] = useState<number>(totalSum);
  const [triggerSearchClear, setTriggerSearchClear] = useState(0);
  const [loading, setLoading] = useState(false);
  const scrollRef: any = useRef();

  const doSequential = useRef(false);

  const lastPage = Math.ceil(total / ITEMS_PER_PAGE);
  const handleSearch = (value: string) => {
    setCurrentPage(1);

    setSearchQuery(value);
  };

  useEffect(() => {
    setTimeout(() => {
      doSequential.current = true;
    }, 1000);
  }, []);

  useEffect(() => {
    if (!doSequential.current) return;

    const top = scrollRef?.current?.offsetTop;
    window.scrollTo({ top: top - 100, behavior: "smooth" });

    if (storybook) {
      setTotal(mockBogsList.list.length);
      setCards(mockBogsList.list);
    } else {
      (async () => {
        try {
          setLoading(true);
          const associatedCategorySysID = selectedCategory.value === "allCategories" ? "" : selectedCategory.value;
          const res = await fetchContentful(associatedCategorySysID, currentPage - 1, searchQuery, dict.read_more);

          if (res.error) {
            console.log(res.error || res.error.message);
            // ! throw toast?!
          }

          setTotal(res.total);
          setCards(res.cardsList);
        } catch (err) {
          console.log("Error occurred when fetching filters");
          console.log(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [searchQuery, currentPage, selectedCategory, preview, dict.read_more, storybook]);

  const popularCategories = () => {
    const popular = allCategories?.slice(1, 5);

    return (popular || []).map((pop) => (
      <button
        id="button-blog-list"
        key={pop.value}
        onClick={() => setCategory(pop.value)}
        className="px-4 py-[6px] rounded-[5px] border-[1px] border-slate-10 outline-none focus-visible:outline-dijon hover:bg-black-5"
      >
        <AppText type="BUTTON_SMALL">{pop.name}</AppText>
      </button>
    ));
  };

  const setCategory = (value: string) => {
    const filtered = (allCategories || []).find((cat: any) => cat.value === value);

    if (filtered && filtered.value !== selectedCategory.value) {
      setCurrentPage(1);

      setSelectedCategory(filtered);
      setTriggerSearchClear((prev) => prev + 1);
      handleSearch("");
    }
  };

  const handleSelectedCategory = (value: { [key: string]: string }) => {
    setCurrentPage(1);
    setSelectedCategory(value);
  };

  return (
    <ContentLayout bgColorClass="bg-white" id={id} paddingBottom={paddingBottom}>
      {!storybook && (
        <div className="sm:gap-6 flex flex-col gap-4 pb-8" ref={scrollRef}>
          <div className="sm:flex-row sm:gap-6 sm:items-center flex flex-col justify-between gap-4">
            <div className="w-full sm:max-w-[386px] ">
              <Select
                selectedItem={selectedCategory}
                setSelected={(selectedCategory: any) => handleSelectedCategory(selectedCategory)}
                options={categories}
                disabled={!allCategories}
              />
            </div>

            <div className="w-full sm:max-w-[386px] ">
              <SearchInput onSearch={handleSearch} clearSearch={triggerSearchClear} />
            </div>
          </div>
          <div className="flex text-[14px]">
            {searchQuery ? (
              cards.length ? (
                <>
                  <span>
                    {`Showing ${(currentPage - 1) * ITEMS_PER_PAGE + 1}-${
                      (currentPage - 1) * ITEMS_PER_PAGE + cards.length
                    }  of ${total} results for`}
                  </span>
                  <span className="font-semibold">&nbsp;&apos;{searchQuery}&apos;</span>
                </>
              ) : null
            ) : (
              total > 0 && (
                <span>
                  {total} {total === 1 ? dict.result : dict.results}
                </span>
              )
            )}
          </div>
        </div>
      )}

      {loading ? (
        <>
          <div className="flex items-center">
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
          </div>
        </>
      ) : !cards.length && !total ? (
        <div className="flex flex-col">
          <AppText type="HEADLINE_SMALL" className="mb-4">
            {`${dict.sorryNoResults} "${searchQuery ? searchQuery : selectedCategory.name}"`}
          </AppText>
          <AppText type="BODY_MEDIUM" className="text-black-80 mb-14">
            {dict.tryDifferentCategory}
          </AppText>
          <AppText type="TITLE_LARGE" className="mb-6">
            {dict.filterCategories}
          </AppText>

          <div className="flex flex-wrap gap-2">{popularCategories()}</div>
        </div>
      ) : (
        <div className="max-w-[1328px] mx-auto grid grid-cols-1 2xl:grid-cols-3 sm:grid-cols-2 gap-4 xl:gap-10 md:gap-6 ">
          {cards.map((blog: any) => {
            return (
              <CustomLink
                key={blog.id}
                href={blog.articleUrl}
                className="flex flex-col overflow-hidden transition h-[100%] group"
              >
                <div className="relative w-full overflow-hidden aspect-[16/9]">
                  <Image
                    className="group-hover:scale-105 object-cover w-full transition"
                    sizes="(max-width: 640px) 100vw, (max-width: 1530px) 50vw, 500px"
                    fill
                    placeholder="blur"
                    blurDataURL={blog.placeholder}
                    src={blog.imgSrc}
                    alt={blog.imgAlt || "image"}
                  />
                </div>

                <div className="flex flex-col items-start flex-1 py-4">
                  <AppText className="line-clamp-2 mb-4" type="SUB_HEADING_MEDIUM">
                    {blog.title}
                  </AppText>

                  <AppIconButton
                    tabIndex={-1}
                    intent="ghost"
                    label={blog.buttonLabel}
                    icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                    iconOnRightSide
                    className="mt-auto"
                  />
                </div>
              </CustomLink>
            );
          })}
        </div>
      )}

      {!loading && total > 12 && (
        <div className="flex justify-center px-6 py-[48px] mt-8">
          <Pagination currentPage={currentPage} lastPage={lastPage} setCurrentPage={setCurrentPage} />
        </div>
      )}
    </ContentLayout>
  );
};

export default BlogsList;

const fetchContentful = async (acSysID: string, skip: number, query: string, read_more: string) => {
  const payload = {
    content_type: "blogArticle",
    include: 10,
    skip: skip * 12,
    limit: 12,
    "fields.category": acSysID,
    query,
    select: ["sys.id", "fields.title", "fields.featuredImage", "fields.slug"],
  };

  try {
    const data = await fetch("/api/contentful", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    const res = await data.json();

    if (res.error) {
      return { cardsList: [], total: 0, error: res.error.message };
    }

    const cardsList = await Promise.all(
      (res.items || []).map(async (item: any) => {
        const _imgSrc = item.fields?.featuredImage?.[0]?.thumbnails?.transformBaseUrl;

        const tmp = {
          id: item.sys.id || "",
          imgSrc: _imgSrc ?? "/placeholders/no-preview.png",
          placeholder: WS_PLACEHOLDER_BLUR_DATA,
          imgAlt: item.fields?.featuredImage?.[0]?.description,
          title: item.fields?.title || "",
          articleUrl: `/resources/blogs/${item.fields?.slug}`,
          buttonLabel: read_more,
          type: "blog",
        };

        return tmp;
      })
    );

    return { cardsList, total: res.total };
  } catch (error: any) {
    console.log(error);

    return { cardsList: [], total: 0, error: error.message };
  }
};
