/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import { usePayAnInvoiceFormData } from "@/store/payAnInvoiceFormsStore";
import AppButton from "../buttons/AppButton";
import AppText from "../other/AppText";

import { useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

import { cn } from "@/lib/utils";
import { Textarea } from "../ui/textarea";
import { format, parseISO } from "date-fns";
import { OTHERS_SELECT_OPTION_CODE } from "./constants";

const formatDate = (date: string) => {
  const parseDate = parseISO(date);
  const formattedDate = format(parseDate, "MMM dd, yyyy");

  return formattedDate.replace(",", "");
};

const getReasons = (data: any): any => {
  const reasonsList: any = [];
  data.forEach((item: { fields: { key: any; title: any } }, index: number) => {
    if (index > 1) {
      reasonsList.push({
        id: item.fields.key,
        title: item.fields.title,
      });
    }
  });
  return reasonsList;
};

const getHeadingForMobile = (data: any, invoiceResponse: any): any => {
  const invoiceStatementItem = invoiceResponse?.STATEMENT_LINES?.item[0];
  const invoiceLineItem = invoiceStatementItem?.LINES?.item?.[0];
  const mobileViewRes: any = [];
  const heading: any = [];
  const values: any = [];
  data.forEach((item: { fields: { name: any }[] }, index: number) => {
    if (index == 0) {
      heading.push(item?.fields?.[1]?.name);
      heading.push(item?.fields?.[2]?.name);
    } else {
      heading.push(item?.fields?.[0]?.name);
    }
  });

  values.push(invoiceResponse?.ORDER_ADDRESSES?.item?.[0]?.STREET);
  values.push(invoiceLineItem?.ALLOC_NMBR);
  values.push(formatDate(invoiceStatementItem?.DOC_DATE));
  values.push(formatDate(invoiceStatementItem?.BLINE_DATE));
  values.push(invoiceStatementItem?.DOC_NO);
  values.push(`$ ${invoiceStatementItem?.BALANCE}`);

  heading.forEach((item: any, index: number) => {
    if (index < 6) {
      mobileViewRes.push({
        id: index,
        heading: heading[index],
        values: values[index],
      });
    } else {
      mobileViewRes.push({
        id: index,
        heading: heading[index],
        values: "",
      });
    }
  });
  return mobileViewRes;
};

const getHeadingForWeb = (data: any, invoiceResponse: any): any => {
  const invoiceStatementItem = invoiceResponse?.STATEMENT_LINES?.item[0];

  const mobileViewRes: any = [];
  const heading: any = [];
  const values: any = [];
  data.forEach((item: { fields: { name: any }[] }, index: number) => {
    if (index == 0) {
      heading.push(item?.fields?.[1]?.name);
      heading.push(item?.fields?.[2]?.name);
    } else {
      heading.push(item?.fields?.[0]?.name);
    }
  });

  values.push(invoiceResponse?.ORDER_ADDRESSES?.item?.[0]?.STREET);
  values.push(formatDate(invoiceStatementItem?.DOC_DATE));
  values.push(formatDate(invoiceStatementItem?.BLINE_DATE));
  values.push(invoiceStatementItem?.DOC_NO);
  values.push(`$ ${invoiceStatementItem?.BALANCE}`);

  values.forEach((item: any, index: string | number) => {
    mobileViewRes.push({
      id: index,
      heading: heading[index],
      values: values[index],
    });
  });
  return mobileViewRes;
};

const FormRequestPayAnInvoiceStep2 = ({ formData, label }: { formData: any; label: any }) => {
  const invoiceResponse = usePayAnInvoiceFormData((state) => state.customerInvoiceResponse);
  const invoiceStatementItem = invoiceResponse?.STATEMENT_LINES?.item?.[0];
  const invoiceLineItem = invoiceStatementItem?.LINES?.item?.[0];
  const formFieldoptions = formData[6].fields[1].fieldOptions;

  const setStepAndFormDataSecondTab = usePayAnInvoiceFormData((state) => state.setStepAndFormDataSecondTab);
  const setStep = usePayAnInvoiceFormData((state) => state.setStep);
  const formDataSecondTab = usePayAnInvoiceFormData((state) => state.formDataSecondTab);
  // const resetForm = usePayAnInvoiceFormData((state) => state.resetForm);
  const resetFormDataSecondTab = usePayAnInvoiceFormData((state) => state.resetFormDataSecondTab);

  const [paymentAmount, setPaymentAmount] = useState<string>(
    formDataSecondTab.Payment_amount ?? invoiceStatementItem?.BALANCE.toString()
  );
  const [selectedOption, setSelectedOption] = useState(formDataSecondTab.Selected_option ?? "");
  const [nextEnable, setNextEnable] = useState(true);
  const [isDisable, setIsDisable] = useState(formDataSecondTab.isDisable ? false : true);
  const [reason, setReason] = useState(formDataSecondTab.enteredReason ?? "");
  const [selectedReason, setSelectedReason] = useState("");

  const onHanldeSubmit = () => {
    if (Number(paymentAmount) > Number(invoiceStatementItem?.BALANCE)) {
      setPaymentAmount(Number.parseFloat(invoiceStatementItem?.BALANCE).toFixed(2));

      setStepAndFormDataSecondTab(3, {
        Amount_due: getHeadingForWeb(formData, invoiceResponse)[4].values,
        Payment_amount: invoiceStatementItem.BALANCE,
        Selected_option: selectedOption,
        Reason: selectedOption === OTHERS_SELECT_OPTION_CODE.othersReasonCode ? reason : selectedReason,
        Currency: invoiceStatementItem?.CURRENCY,
        isDisable: isDisable ? "" : "1",
        enteredReason: reason,
      });
    } else {
      setStepAndFormDataSecondTab(3, {
        Amount_due: getHeadingForWeb(formData, invoiceResponse)[4].values,
        Payment_amount: Number.parseFloat(paymentAmount).toFixed(2).toString(),
        Selected_option: selectedOption,
        Reason: selectedOption === OTHERS_SELECT_OPTION_CODE.othersReasonCode ? reason : selectedReason,
        Currency: invoiceStatementItem?.CURRENCY,
        isDisable: isDisable ? "" : "1",
        enteredReason: reason,
      });
    }
  };

  const onHandleDropdown = (item: string) => {
    const selectedOption = getReasons(formFieldoptions).filter((select: { id: string; title: string }) => {
      if (select.id === item) {
        return select.title;
      }
    });
    if (selectedOption[0].id === OTHERS_SELECT_OPTION_CODE.othersReasonCode) {
      setNextEnable(false);
    } else {
      setNextEnable(true);
    }

    setSelectedReason(selectedOption[0]?.title ?? "");
    setSelectedOption(item);
    //setNextEnable(true);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const decimalRegex = /^[0-9]*\.?[0-9]*$/; // Regex to match decimal numbers
    let input = event.target.value;

    if (!decimalRegex.test(input)) {
      return;
    }

    if (!/^\d*\.?\d{0,2}$/.test(input)) {
      return;
    }

    if (isNaN(Number(input))) {
      input = "";
      setPaymentAmount("");
    }
    if (Number(input) == Number(invoiceStatementItem?.BALANCE)) {
      setSelectedOption("");
      setReason("");
      setSelectedReason("");
      setIsDisable(true);
      setPaymentAmount(input);
    } else if (Number(input) > Number(invoiceStatementItem?.BALANCE)) {
      setIsDisable(true);
      setPaymentAmount(input);
      setSelectedOption("");
      setReason("");
      setSelectedReason("");
      setNextEnable(false);
    } else {
      setIsDisable(false);
      setPaymentAmount(input);
      if (selectedOption == "") {
        setNextEnable(false);
      }
    }
  };

  const onHandleBlur = () => {
    if (Number(paymentAmount) > Number(invoiceStatementItem?.BALANCE)) {
      setIsDisable(true);
      setPaymentAmount(Number.parseFloat(invoiceStatementItem?.BALANCE).toFixed(2));
      setSelectedOption("");
      setReason("");
      setSelectedReason("");
      setNextEnable(true);
      return;
    }
    if (paymentAmount == "") {
      setPaymentAmount("0.00");
    } else {
      setPaymentAmount(Number.parseFloat(paymentAmount).toFixed(2));
    }
  };

  const onHandleTextArea = (item: any) => {
    if (item.target.value.length == 0) {
      setNextEnable(false);
    } else {
      setNextEnable(true);
    }
    setReason(item.target.value);
  };

  return (
    <div className="lg:col-span-10 lg:col-start-2 col-span-4 px-6 pt-12 bg-white">
      <AppText type="SUB_HEADING_MEDIUM" className="mb-[28px]">
        {label}
      </AppText>

      <div className="lg:block md:hidden hidden">
        <div className="lg:grid-cols-1 grid">
          <div className="lg:grid-cols-7 grid gap-4 bg-[#E6EFF8] border-b-[1px] border-b-[#CDD2D5] lg:px-[20px] lg:py-[10px] px-[10px] py-[5px] ">
            {formData.map((item: any, index: number) => (
              <div key={item} className={`lg:text-start lg:items-center  flex  `}>
                {index == 0 ? (
                  <div className=" overflow-clip flex flex-col w-full">
                    <AppText type="BUTTON_SMALL" className="font-[700]">
                      {item.fields[0].name}
                    </AppText>
                  </div>
                ) : (
                  <AppText type="BUTTON_SMALL" className="font-[700]">
                    {item.fields[0].name}
                  </AppText>
                )}
              </div>
            ))}
          </div>
          <div className=" lg:grid-cols-7  grid gap-4 bg-[#E6EFF8] border-b-[1px] border-b-[#CDD2D5] lg:px-[20px] lg:py-[10px]  ">
            {getHeadingForWeb(formData, invoiceResponse).map((item: any, index: number) => (
              <div
                key={item}
                className={`lg:text-start lg:justify-start  lg:items-center flex   overflow-clip w-full
                `}
              >
                {index == 0 ? (
                  <div className=" overflow-clip flex flex-col w-full">
                    <AppText type="BODY_SMALL" className=" truncate font-[600]">
                      {item.values}
                    </AppText>
                    <AppText type="BODY_SMALL" className="truncate">
                      {invoiceLineItem?.ALLOC_NMBR}
                    </AppText>
                  </div>
                ) : (
                  <AppText type="BODY_SMALL" className="truncate">
                    {item.values}
                  </AppText>
                )}
              </div>
            ))}
            <div className="lg:text-start lg:items-center lg:justify-start flex justify-end">
              <input
                className="lg:h-[40px] h-[30px] w-full px-2 border border-black border-1 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                type="text"
                id="myTextField"
                value={paymentAmount}
                disabled={Number(paymentAmount) < 0}
                onChange={onHandleChange}
                min={0}
                onBlur={onHandleBlur}
              />
            </div>
            <div className=" lg:text-start lg:items-start lg:justify-center lg:flex-col flex flex-wrap items-start justify-end">
              <div className="lg:flex-col lg:justify-start lg:items-start flex flex-col items-end justify-end">
                <Select disabled={isDisable} onValueChange={onHandleDropdown} value={selectedOption}>
                  <SelectTrigger
                    className={cn(
                      "!mt-0 border-[#898A8D] placeholder:text-[#64748b] rounded-[5px] w-[100px] lg:h-[40px] h-[24px]"
                    )}
                    title="label"
                  >
                    <SelectValue
                      placeholder={`${formFieldoptions?.[0]?.fields.title}`}
                      className="placeholder:text-[#64748b]"
                    />
                  </SelectTrigger>

                  <SelectContent
                    style={{
                      position: "relative",
                      zIndex: 1300,
                      top: "auto",
                      maxHeight: "250px",
                      overflowY: "auto",
                      width: "300px",
                    }}
                  >
                    {getReasons(formFieldoptions).map((dur: any) => (
                      <SelectItem aria-label={dur.title} key={dur.id} value={dur.id}>
                        {dur.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {selectedOption == OTHERS_SELECT_OPTION_CODE.othersReasonCode && !isDisable && (
                  <Textarea
                    placeholder={`${formFieldoptions?.[1]?.fields.title}`}
                    id="text-area"
                    className="mt-1"
                    value={reason}
                    onChange={onHandleTextArea}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden block">
        <div className="bg-[#E6EFF8] flex flex-col flex-1 px-4 py-4 border-b-[1px] border-b-[#CDD2D5]">
          {getHeadingForMobile(formData, invoiceResponse).map((ele: any, index: any) => {
            return (
              <div className="flex-2 flex flex-row w-full mb-1">
                <div className=" flex flex-wrap flex-1">
                  <AppText type="BODY_SMALL" className="truncate font-[600]">
                    {ele.heading}
                  </AppText>
                </div>
                <div className="text-ellipsis flex flex-wrap justify-end flex-1 overflow-hidden">
                  {index <= 5 && (
                    <AppText type="BODY_SMALL" className="f truncate">
                      {ele.values}
                    </AppText>
                  )}
                  {index == 6 && (
                    <input
                      type="text"
                      id="myTextField"
                      disabled={Number(paymentAmount) < 0}
                      value={paymentAmount}
                      className="w-[100px]  border-[1px] p-1 border-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      onChange={onHandleChange}
                      min={0}
                      onBlur={onHandleBlur}
                    />
                  )}
                  {index == 7 && (
                    <div className=" flex-col">
                      <div className="flex justify-end flex-1 space-y-0">
                        <Select disabled={isDisable} onValueChange={onHandleDropdown} value={selectedOption}>
                          <SelectTrigger
                            className={cn(
                              "!mt-0 border-[#898A8D] placeholder:text-[#64748b] rounded-[5px] w-[100px] lg:h-[40px] h-[24px]"
                            )}
                            title="label"
                          >
                            <SelectValue
                              placeholder={`${formFieldoptions?.[0]?.fields?.title}`}
                              className="placeholder:text-[#64748b]"
                            />
                          </SelectTrigger>

                          <SelectContent
                            style={{
                              position: "relative",
                              zIndex: 1300,
                              top: "auto",
                              maxHeight: "200px",
                              overflowY: "auto",
                              width: "180px",
                            }}
                          >
                            {getReasons(formFieldoptions).map((dur: any) => (
                              <SelectItem aria-label={dur.title} key={dur.id} value={dur.id}>
                                {dur.title}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                      {selectedOption == OTHERS_SELECT_OPTION_CODE.othersReasonCode && !isDisable && (
                        <div>
                          <Textarea
                            placeholder={`${formFieldoptions?.[1]?.fields?.title}`}
                            className="mt-1"
                            id="text-area"
                            value={reason}
                            onChange={onHandleTextArea}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-24 mb-[78px]">
        <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>
        <div className="flex flex-row flex-wrap justify-end gap-4 bg-white">
          <AppButton
            intent="secondary"
            label="Back"
            type="button"
            className="px-10"
            onMouseDown={() => {
              setStep(1);
              resetFormDataSecondTab();
            }}
          />
          <AppButton
            disabled={
              Number(invoiceStatementItem?.BALANCE) < 0 ||
              Number(paymentAmount) == 0.0 ||
              isNaN(Number(paymentAmount)) ||
              !nextEnable
            }
            intent="primary"
            label="Next"
            type="submit"
            className="px-10"
            onMouseDown={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default FormRequestPayAnInvoiceStep2;
