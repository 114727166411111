import React from "react";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import Link from "next/link";
import { ExpandMore24 } from "@/assets/Icons";
import { PrevButton, NextButton, usePrevNextButtons } from "./CarouselPrevNextButton";
import { DotButton, useDotButton } from "./CarouselDotButtons";
import AppText from "../other/AppText";
import Image from "next/image";
import styles from "./style.module.css";

export interface ISlidesProps {
  id: string;
  heading: string;
  imgUrl: string;
  readMoreUrl: string;
}

interface ICarouselProps {
  slides: ISlidesProps[];
  options?: EmblaOptionsType;
  heading?: string;
  readMore?: string;
}

const EmblaItemsCarousel: React.FC<ICarouselProps> = (props) => {
  const { slides, options, heading, readMore } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const { selectedIndex, onDotButtonClick } = useDotButton(emblaApi);
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  return (
    <section className={styles.carousel_section}>
      <div className="flex flex-row w-full">
        <div className="basis-2/3">
          <AppText type="HEADLINE_MEDIUM" className="mb-1">
            <div className={`font-sairaSemiCondensed text-left ${styles.title_heading}`}>{heading}</div>
          </AppText>
        </div>
        <div className="basis-1/3 alignItems-center text-right">
          <div className={styles.carousel_buttons}>
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </div>
        </div>
      </div>
      <div className={styles.carousel_viewport} ref={emblaRef}>
        <div className={styles.carousel_container}>
          {slides?.map((item: ISlidesProps) => (
            <div className={styles.carousel_slide} key={item.id}>
              <div className={styles.carousel_slide_item}>
                <div className={styles.image_item}>
                  <Image src={item.imgUrl} width={302} height={182} alt={item?.heading} />
                </div>
                <div className={styles.article_content}>
                  <div className={styles.blog_carousel_heading} title={item?.heading}>
                    {item?.heading || "No title is comming"}
                  </div>
                  <Link href={item?.readMoreUrl} target="_blank" className={styles.read_more_btn}>
                    {readMore}
                    <ExpandMore24 className={styles.right_arrow_icon} />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.carousel_dot_controls}>
        <div className={styles.carousel_dots_row}>
          {slides?.map((_, index) => {
            if (index > slides.length - 4) return;
            return (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={`${styles.carousel_dot_item}`.concat(
                  index === selectedIndex ? ` ${styles.carousel_dot_selected}` : ""
                )}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default EmblaItemsCarousel;
