/* eslint-disable @typescript-eslint/no-explicit-any */
import { usePayAnInvoiceFormData } from "@/store/payAnInvoiceFormsStore";

import StepperServices from "./StepperServices";
import FormRequestPayAnInvoiceStep1 from "./FormRequestPayAnInvoiceStep1";
import FormRequestPayAnInvoiceStep2 from "./FormRequestPayAnInvoiceStep2";
import FormRequestPayAnInvoiceStep3 from "./FormRequestPayAnInvoiceStep3";

const FormRequestPayAnInvoice = ({
  formModel: { formTabs, STEPS_TAB_LABELS },
}: {
  formModel: { formTabs: any; STEPS_TAB_LABELS: string[] };
}) => {
  const { step } = usePayAnInvoiceFormData();

  return (
    <>
      <div className="bg-black-5 relative flex justify-center w-full">
        <div className="absolute h-[120px] bg-royal-blue w-full z-1 "></div>
        <div
          className={`
                      w-full mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg
                      px-4 lg:px-8 xl:px-[150px] 2xl:px-[104px] pb-20 z-20
                      grid-cols-4 lg:grid-cols-12 grid gap-y-2 sm:gap-y-6 xl:gap-y-[40px]
                      `}
        >
          <div className="lg:col-span-12 lg:col-start-1 lg:px-[91px] col-span-4 bg-white px-6 border-b border-b-1 border-b-[#CDD2D5]">
            <StepperServices className="bg-white" steps={STEPS_TAB_LABELS} currentStepIndex={step} />

            {step === 1 ? (
              <FormRequestPayAnInvoiceStep1 formData={formTabs[0]} />
            ) : step === 2 ? (
              <FormRequestPayAnInvoiceStep2 formData={formTabs[1].groups[0].rows} label={formTabs[1].tabLabel} />
            ) : step === 3 ? (
              <FormRequestPayAnInvoiceStep3 formDataTab={formTabs[2]} />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormRequestPayAnInvoice;
