/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import { EmblaOptionsType } from "embla-carousel";
import ContentLayout from "../layouts/ContentLayout";
import EmblaItemsCarousel from "../embla-carousel/EmblaItemsCarousel";

const OPTIONS: EmblaOptionsType = { align: "start" };

const BlogsNewsEventsCarousel: React.FC<any> = ({ heading, data, bgColor, readMore }) => {
  return (
    <>
      <ContentLayout bgColorClass={`bg-${bgColor}`} innerClass="flex flex-col items-center">
        <EmblaItemsCarousel heading={heading} readMore={readMore} slides={data} options={OPTIONS} />
      </ContentLayout>
    </>
  );
};

export default BlogsNewsEventsCarousel;
