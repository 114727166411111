/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";

interface IFormData {
  formData: { [key: string]: string };
  formDataSecondTab: { [key: string]: string };
  setFormData: (data: { [key: string]: string }) => void;
  setStepAndFormDataSecondTab: (step: number, data: { [key: string]: string }) => void;
  step: number;
  isInvoice: boolean;
  setStep: (step: number) => void;
  setStepAndFormData: (step: number, data: { [key: string]: string }) => void;
  resetForm: () => void;
  setIsInvoice: (invoice: boolean) => void;
  customerInvoiceResponse: any;
  setCustomerInvoiceResponse: (data: any) => void;
  resetFormDataSecondTab: () => void;
}

export const usePayAnInvoiceFormData = create<IFormData>((set) => ({
  step: 1,
  formData: {},
  formDataSecondTab: {},
  isInvoice: true,
  customerInvoiceResponse: {},
  setCustomerInvoiceResponse: (data: any) => set(() => ({ customerInvoiceResponse: data })),

  setFormData: (data: { [key: string]: string }) => set((state) => ({ formData: { ...state.formData, ...data } })),
  setStepAndFormDataSecondTab: (step: number, data: { [key: string]: string }) =>
    set((state) => {
      return { step: step, formDataSecondTab: { ...state.formDataSecondTab, ...data } };
    }),
  setStep: (step: number) => set(() => ({ step: step })),
  setIsInvoice: (invoice: boolean) => set(() => ({ isInvoice: invoice })),
  setStepAndFormData: (step: number, data: { [key: string]: string }) =>
    set((state) => {
      return { step: step, formData: { ...state.formData, ...data } };
    }),
  resetForm: () => set(() => ({ formData: {}, formDataSecondTab: {}, step: 1 })),
  resetFormDataSecondTab: () => set(() => ({ formDataSecondTab: {} })),
}));
