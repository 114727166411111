/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import AppButton from "../buttons/AppButton";
import { FieldText, FieldPhone, FieldCalendar, FieldTextArea, FieldDropdown } from "./FormElements";
import { usePayAnInvoiceFormData } from "@/store/payAnInvoiceFormsStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useEffect, useMemo } from "react";
import { z } from "zod";
import { createDynamicSchemaSupportThirdTab } from "./schemaHelper";
import { Form } from "../ui/form";
import AppText from "../other/AppText";
import { InvoicesPayload } from "@/app/[lang]/pay-an-invoice/invoices";
import { fetchFromAPI } from "@/app/[lang]/pay-an-invoice/apiService";
import { API_CONFIG } from "./constants";
import LoadingDots from "../other/LoadingDots";

interface Params {
  account_number: string;
  invoice_no: string;
  additional_data: boolean;
}

const FormRequestPayAnInvoiceStep1 = ({ formData }: { formData: any }) => {
  const formSchema = z.object({});

  const [dynamicFormSchema, setDynamicFormSchema] = useState<z.ZodSchema>(formSchema);

  const setStepAndFormData = usePayAnInvoiceFormData((state) => state.setStepAndFormData);
  const formDataStore = usePayAnInvoiceFormData((state) => state.formData);
  const setInvoiceData = usePayAnInvoiceFormData((state) => state.setCustomerInvoiceResponse);

  const [isLoading, setIsLoading] = useState(false);

  const formDefaultValues = useMemo(() => {
    const res: any = {};

    (formData.groups[0].rows || []).forEach((row: any) => {
      (row.fields || []).forEach((field: any) => {
        res[field.name] = formDataStore[field.name] || "";
      });
    });
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const form = useForm<z.infer<typeof dynamicFormSchema>>({
    resolver: zodResolver(dynamicFormSchema),
    defaultValues: formDefaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    const createDynamicSchema = () => {
      const zObj = createDynamicSchemaSupportThirdTab(formData.groups);
      const formSchema = z.object(zObj);
      setDynamicFormSchema(formSchema);
    };

    createDynamicSchema();
  }, [formData]);

  async function onSubmit(values: z.infer<typeof dynamicFormSchema>) {
    setIsLoading(true);
    const params: Params = {
      account_number: values.PAI_Account,
      invoice_no: values.PAI_Invoice,
      additional_data: true,
    };
    const headers = {
      guestpaymentkey: `${process.env.NEXT_PUBLIC_GUEST_PAYMENT_KEY}`,
    };
    const invoiceResponse = await fetchFromAPI<InvoicesPayload>({
      method: "get",
      endpoint: API_CONFIG.ENDPOINTS.invoices,
      queryParameters: params,
      headers: headers,
    });
    setIsLoading(false);

    if (invoiceResponse.RETURN != "") {
      const invoiceErr = formData.groups[0].rows[2].fields.filter((item: { name: string }) => {
        if (item.name === "PAI_Invoice") {
          return item;
        }
      });

      const accountErr = formData.groups[0].rows[2].fields.filter((item: { name: string }) => {
        if (item.name === "PAI_Account") {
          return item;
        }
      });
      form.setError("PAI_Invoice", { message: invoiceErr[0].invalidError });
      form.setError("PAI_Account", { message: accountErr[0].invalidError });
    } else {
      setInvoiceData(invoiceResponse);

      setStepAndFormData(2, values);
    }
  }

  const handleNextButton = () => {
    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  return (
    <>
      {isLoading ? (
        <div className=" m-7 flex items-center justify-center">
          <LoadingDots />
        </div>
      ) : (
        <div className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-6 pt-12 bg-white">
          <AppText type="SUB_HEADING_MEDIUM" className="mb-[28px]">
            {formData.groups[0].heading}
          </AppText>
          <Form {...form}>
            <form
              className="flex flex-col bg-white gap-[28px]"
              autoComplete="off"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              {(formData.groups[0].rows || []).map((row: any) => (
                <div key={row.id} className="lg:flex-row flex flex-col gap-6">
                  {row.fields?.length &&
                    row.fields.map((field: any) => {
                      if (field.fieldType === "fieldText") {
                        return (
                          <FieldText
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                            type={field.name === "PAI_Invoice" || field.name === "PAI_Account" ? "number" : ""}
                          />
                        );
                      } else if (field.fieldType === "fieldPhone") {
                        console.log(field.id, field.name, field.label, "phoneData");
                        return (
                          <FieldPhone
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            loading={false}
                            placeholder={field.placeholder || "Enter your phone number..."}
                            label={field.label}
                          />
                        );
                      } else if (field.fieldType === "fieldCalendar") {
                        return (
                          <FieldCalendar
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                          />
                        );
                      } else if (field.fieldType === "fieldTextArea") {
                        return (
                          <FieldTextArea
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            loading={false}
                            placeholder={field.placeholder}
                          />
                        );
                      } else if (field.fieldType === "fieldDropdown") {
                        return (
                          <FieldDropdown
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            label={field.label}
                            options={field.options}
                          />
                        );
                      } else if (field.fieldType === "fieldEmail") {
                        return (
                          <FieldText
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              ))}
              <div className="mt-12 mb-[78px]">
                <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>
                <div className="flex flex-row flex-wrap justify-end gap-4 bg-white">
                  <AppButton
                    intent="secondary"
                    label={formData.ctaBack}
                    type="button"
                    className="px-10"
                    onMouseDown={() => {}}
                  />
                  <AppButton
                    intent="primary"
                    label={formData.ctaNext}
                    type="submit"
                    className="px-10"
                    onMouseDown={handleNextButton}
                  />
                </div>
              </div>
            </form>
          </Form>
        </div>
      )}
    </>
  );
};
export default FormRequestPayAnInvoiceStep1;
