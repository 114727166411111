/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useMemo, useState } from "react";
import { SwiperSlide } from "swiper/react";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/other/AppText";
import { AppButton } from "../buttons/AppButton";
import Carousel from "@/components/other/Carousel";
import { parseData } from "../salesShowroom/helpers";
import ShowroomCard from "../salesShowroom/ShowroomCard";
import { colorsMap, fallbackColors, IDict } from "../other/constants";
import { cn } from "@/lib/utils";

interface IUsedProductMoreLikeThis {
  background: string;
  headingColoured: string;
  ctaLabel: string;
  ctaLink: string;
  heading: string;
  similar: any;
  manuallyAddedProducts: any;
  label: string;
  subheading: string;
  dict: IDict;
  el: any;
}

const UsedProductMoreLikeThis = ({
  el,
  similar,
  manuallyAddedProducts,
  dict,
  background,
  label,
  subheading,
  headingColoured,
  ctaLabel,
  ctaLink,
  heading,
}: IUsedProductMoreLikeThis) => {
  const { textColor, bgColorClass, bg, labelColor } = colorsMap[background] ?? fallbackColors;

  const [isMatching, setIsMatching] = useState(false);
  useEffect(() => {
    const usedproductTemp = el.fields.internalLabel;
    setIsMatching(usedproductTemp.includes("Units for Sale Near Me"));
  }, [el.fields.internalLabel]);

  const cards = useMemo(() => {
    if (!similar) return [];
    const res = parseData(similar?.items || [], similar?.includes?.Entry || [], dict.viewUnit, dict.purchaseInquiry);

    return res || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [similar]);

  return (
    <ContentLayout bgColorClass={bgColorClass}>
      {label && (
        <AppText className={cn(labelColor)} type={"LABEL_LARGE"}>
          {label}
        </AppText>
      )}

      <div className={"mt-[8px] max-sm:flex-col flex flex-1 justify-between"}>
        <div className={"flex flex-col flex-[0.8] mr-[40px]"}>
          <div className="flex flex-row gap-4">
            <AppText type={"HEADLINE_MEDIUM"} className={cn(textColor)}>
              {heading}
            </AppText>
            <AppText type={"HEADLINE_MEDIUM"} className="text-mellow">
              {headingColoured}
            </AppText>
          </div>

          <AppText type={"SUB_HEADING_SMALL"} className={cn("mt-4", textColor)}>
            {subheading}
          </AppText>
        </div>

        {!isMatching && (
          <div className={"max-sm:mt-[32px]"}>
            <AppButton intent="secondary" label={ctaLabel} bg={bg} link={ctaLink} />
          </div>
        )}
      </div>
      <Carousel
        wrapperClassName={"mt-[40px] flex"}
        className={"flex-1 flex items-stretch"}
        slidesPerView={1}
        spaceBetween={10}
        paginationType={"pageSelect"}
        bg={bg}
        cardsOverflow={false}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 24,
            slidesPerGroup: 1,
          },

          1024: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 40,
          },
        }}
      >
        {[...manuallyAddedProducts, ...cards].map((card: any, index: number) => (
          <SwiperSlide key={index} className="p-[6px]">
            <ShowroomCard card={card} usedProductMoreLikeThis />
          </SwiperSlide>
        ))}
      </Carousel>
    </ContentLayout>
  );
};

export default React.memo(UsedProductMoreLikeThis);
