"use client";

import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import Link from "next/link";

import ContentLayout from "../layouts/ContentLayout";
import { ExpandMore24 } from "@/assets/Icons";
import { AppButton } from "../buttons/AppButton";
import { useRouter } from "next/navigation";

interface IAnchor {
  label: string;
  id?: string;
  url?: string;
}

export interface IInPageAnchor {
  anchors: IAnchor[];
  paddingBottom?: string;
  heading?: string;
  internalLabel?: string;
}

export interface IInPageAnchor {}

const InPageAnchor: React.FC<IInPageAnchor> = ({ anchors, paddingBottom, heading, internalLabel }) => {
  const router = useRouter();
  const handleAnchorsClick = (anchor: IAnchor) => {
    if (anchor.id) {
      const target = document.getElementById(anchor.id);

      if (target) {
        const position = target.getBoundingClientRect();

        window.scrollTo(position.left, position.top + window.scrollY - 150);
      } else console.log("No Target with scroll ID");
    } else if (anchor.url) {
      if (anchor.url.startsWith("/")) {
        router.push(anchor.url);
      }
    }
  };
  return (
    <div>
      <ContentLayout
        bgColorClass={heading ? "bg-navy-blue-custom-bg-Anchor" : "bg-black-5"}
        innerClass="sm:py-1 py-1 px-0"
        paddingBottom={paddingBottom}
        className={heading ? "lg:h-[30px]" : ""}
      >
        {internalLabel?.includes("Explore Configurations") && (
          <div className="lg:flex gap-x-8 flex-wrap items-center hidden w-full mx-auto">
            <div className="inline-flex box-border whitespace-nowrap gap-3 select-none font-sairaSemiCondensed font-[500] text-[13px] text-black-60 flex-shrink-0">
              {heading}
            </div>
            {anchors.map((anchor: IAnchor) => (
              <AppButton
                intent="ghost"
                label={anchor.label}
                key={anchor.id}
                onClick={() => handleAnchorsClick(anchor)}
                className="text-[13px]"
                afterCss="after:bottom-[.7rem]"
              />
            ))}
          </div>
        )}
        {heading && !internalLabel?.includes("Explore Configurations") && (
          <div className="lg:flex gap-x-8 flex-wrap items-center justify-center hidden w-full mx-auto">
            {anchors.map((anchor: IAnchor) => (
              <AppButton
                intent="ghost"
                label={anchor.label}
                key={anchor.id}
                onClick={() => handleAnchorsClick(anchor)}
                className="text-[13px]"
                afterCss="after:bottom-[.7rem]"
              />
            ))}
          </div>
        )}
        {!heading && (
          <div className="hidden lg:flex space-x-[60px] items-center justify-center w-full px-4 py-2">
            {anchors.map((anchor: IAnchor) => (
              <AppButton
                intent="ghost"
                label={anchor.label}
                key={anchor.id}
                onClick={() => handleAnchorsClick(anchor)}
                className="text-18"
              />
            ))}
          </div>
        )}
        <div className="lg:hidden flex">
          <MobileDropdown anchors={anchors} heading={heading} />
        </div>
      </ContentLayout>
    </div>
  );
};

export default InPageAnchor;

const MobileDropdown: React.FC<IInPageAnchor> = ({ anchors, heading }) => {
  const router = useRouter();
  const handleAnchorsClick = (anchor: IAnchor) => {
    if (anchor.id) {
      const target = document.getElementById(anchor.id);

      if (target) {
        const position = target.getBoundingClientRect();

        window.scrollTo(position.left, position.top + window.scrollY - 150);
      } else console.log("No Target with scroll ID");
    } else if (anchor.url) {
      if (anchor.url.startsWith("/")) {
        router.push(anchor.url);
      }
    }
  };
  return (
    <Listbox>
      <div className="relative flex-1 w-full">
        <Listbox.Button
          className={`focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 
          focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 
           relative w-full py-2 px-4 text-left   cursor-pointer `}
        >
          <span className="block truncate">{heading ? heading : "Explore More Sections"}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ExpandMore24 />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options
            className={`max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm absolute w-full 
            overflow-auto text-base bg-white  shadow-lg text-black`}
          >
            {anchors.map((anchor, idx) => {
              return (
                <Listbox.Option
                  className={`relative cursor-pointer select-none py-2 px-4 text-black-80 hover:bg-navy-blue hover:text-white`}
                  value={anchor.label}
                  key={anchor.id || idx}
                  onClick={() => handleAnchorsClick(anchor)}
                >
                  {anchor.label}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
