import axios from "axios";
import { API_CONFIG } from "../../../components/form/constants";

// import * as AxiosLogger from "axios-logger";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // Add any other headers here
  },
});

// Check if the current environment is development/debug
const isDebugMode = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === "dev";

// Request interceptor for logging requests
axiosInstance.interceptors.request.use((request) => {
  if (isDebugMode) {
    // AxiosLogger.requestLogger(request);
  }
  return request;
});

// Response interceptor for logging responses
axiosInstance.interceptors.response.use(
  (response) => {
    if (isDebugMode) {
      // AxiosLogger.responseLogger(response);
    }
    return response;
  },
  (error) => {
    if (isDebugMode) {
      // void AxiosLogger.errorLogger(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
